import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useQuery} from 'urql';

import {readMyselfQuery} from "../lib/graphql/queries";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import theme from "../layout/theme";
import {useLogin} from "../features/Login/LoginContext";
import Bugsnag from "@bugsnag/js";

const MyselfContext = createContext();
const PermissionContext = createContext();

export const useMyself = () => useContext(MyselfContext)
export const usePermissions = () => useContext(PermissionContext)

const MyselfContextProvider = ({ children: reactChildren, addOnsPreset, returnAddOnsPreset }) => {
  // const classes = useStyles();
  const [selectedDepartment, setSelectedDepartment] = useState('')
  const { setLoggedIn } = useLogin()
  const [myself] = useQuery({ query: readMyselfQuery });

  const customerPalette = JSON.parse(myself.data?.readMyself.ThemePalette || '{}')
  const custheme = useMemo(() => theme(customerPalette), [customerPalette])

  useEffect(() => {
    window.localStorage.setItem('securityToken', myself.data?.readMyself.SecurityToken)
  }, [myself])

  useEffect(() => {
    if (myself.data) {
      const user = myself.data.readMyself
      Bugsnag.setUser(user.id, user.Email, `${user.FirstName} ${user.Surname}`)
    }
  }, [myself])

  useEffect(() => {
    if (myself.error) {
      setLoggedIn(false)
    }
  }, [myself, setLoggedIn])

  useEffect(() => {
    if (!selectedDepartment && myself.data?.readMyself.Departments?.length) {
      setSelectedDepartment(myself.data?.readMyself.Departments[0].id)
    }
  }, [selectedDepartment, myself])

  const canAccess = useCallback(code => {
    const currentMember = myself.data?.readMyself

    if (currentMember) {
      return currentMember.Permissions.findIndex(permission => permission === code) >= 0
    }
    return false
  }, [myself])

  if (myself.fetching || !myself.data) {
    return <Backdrop open={true}>
      <CircularProgress color="inherit"/>
    </Backdrop>
  }

  const contextValue = {
    ...myself.data.readMyself,
    custheme: custheme,
    selectedDepartment: selectedDepartment,
    setSelectedDepartment: setSelectedDepartment
  }

  return (
    <MyselfContext.Provider value={contextValue}>
      <PermissionContext.Provider value={{ canAccess }}>
        {reactChildren}
      </PermissionContext.Provider>
    </MyselfContext.Provider>
  );
}

MyselfContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default MyselfContextProvider;
