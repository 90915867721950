import React from 'react';

import {useStyles} from './styles';
import {Card, CardActionArea, CardContent, Grid, Typography} from "@mui/material";
import CalenderIcon from "@mui/icons-material/DateRange";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import SettingsIcon from "@mui/icons-material/Settings";
import TrainingScheduleIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import CalendarViewWeek from "@mui/icons-material/CalendarViewWeek";
import {useMyself} from "../../context/MyselfContext";
import {useLocation} from "wouter";

const DashboardProvider = () => {
  const classes = useStyles();
  const {MenuItems} = useMyself()
  const [, setLocation] = useLocation();

  const icons = {
    CalenderIcon: <CalenderIcon fontSize={'large'} />,
    PlaylistAddIcon: <PlaylistAddIcon fontSize={'large'} />,
    PlaylistAddCheckIcon: <PlaylistAddCheckIcon fontSize={'large'} />,
    SettingsIcon: <SettingsIcon fontSize={'large'} />,
    TrainingScheduleIcon: <TrainingScheduleIcon fontSize={'large'} />,
    CalendarViewWeekIcon: <CalendarViewWeek fontSize={'large'} />,
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {MenuItems.map((item, index) => {
          return (
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem} key={index}>
              <Card className={classes.card} onClick={() => setLocation(item.Link)}>
                <CardActionArea>
                  <CardContent>
                    <Typography gutterBottom align={"center"} variant="h5" component="h2">
                      {icons[item.Icon]}<br/>
                      {item.Title}
                    </Typography>
                    <Typography variant="body2" align={"center"} color="textSecondary" component="p">
                      {item.Description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </div>
  );
}

DashboardProvider.propTypes = {}

export default DashboardProvider;
